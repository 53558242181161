import { ErrorCodes } from "@vue/compiler-core";
import PlatformsService from "../common/platforms.service";
import {JOB_CREATE, PLATFORMS_GET, STATUS_GET, LIBRARY_GET } from './actions.type';
import {PLATFORMS_SET, STATUS_SET, LIBRARY_SET} from "./mutations.type";

const initialState = {
  platforms: [],
  status: [],
  library: {}
 };

export const state = { ...initialState };

export const actions = {
  async [PLATFORMS_GET] ({ commit }) {
    const { data } = await PlatformsService.getAllServices();
    commit(PLATFORMS_SET, data);
    return data;
  },
  async [LIBRARY_GET] ({ commit }, platform) {
    const { data, } = await PlatformsService.getLibrary(platform);
    commit(LIBRARY_SET, data);
    return data;
    // PlatformsService.getLibrary(platform)
    // .then(res => console.log("LIBRARY_GET res",res))
    // .catch(function(error) {
    //   console.log('LIBRARY_GET error',error);
    // });
  },
  async [JOB_CREATE] ({ commit }, {platform, migration_id, playlists, state}) {
    const { data } = await PlatformsService.createJob(platform, {
      migration_id: migration_id,
      playlists: playlists,
      state: state,
    });

    return data;
  },
  async [STATUS_GET] ({ commit }, id) {
    const { data } = await PlatformsService.getStatus(id);
    const TASK_KEY = Object.freeze({
      TRACK: 'track',
      PLAYLIST: 'playlist',
      ALBUM: 'album'
    });
    // const response = data.tasks
    //   .filter(task => task.status !== proto.TaskStatusType.TASKSTATUSUNKNOWN)
    //   .sort((a, b) => {
    //     return a.type - b.type;
    //   })
    //   .map(task => {
    //     const candidateType = Object.entries(proto.MediaType).find((p) => p[1] === task.type);
    //     const stringType = candidateType ? candidateType[0] : Object.keys(proto.PlatformType)[0];

    //     return {
    //       ...task,
    //       stringType,
    //       title: `${stringType}_TITLE`,
    //       status: {
    //         error: task.error,
    //         type: task.status,
    //         total: task.total,
    //         current: task.current
    //       }
    //     };
    //   });

    const response = {
      tasks: Object.entries(TASK_KEY)
      .filter(task => Object.prototype.hasOwnProperty.call(data, task[1]))
      .map(task => {
        return {
          stringType: task[1],
          title: `${task[0]}_TITLE`,
          status: {
            completed: !!data[task[1]].completed,
            error: data[task[1]].error ? data[task[1]].error : '',
            processed: data[task[1]].processed,
            total: data[task[1]].total
          }
        };
      }),
      completed: !!data.completed,
      error: data.error ? data.error : ''
    };

    commit(STATUS_SET, response);
    return response;
  }
};

export const mutations = {
  [PLATFORMS_SET](state, platforms) {
    state.platforms = platforms;
  },
  [STATUS_SET](state, status) {
    state.status = status;
  },
  [LIBRARY_SET](state, library) {
    state.library = library;
  }
};

const getters = {
  platforms(state) {
    return state.platforms;
  },
  platformById(state) {
    return (id) => {
      return state.platforms.find(platform => platform.type === id);
    };
  },
  platformByName(state) {
    return (name) => {
      return state.platforms.find(platform => platform.name === name);
    };
  },
  status(state) {
    return state.status;
  },
  library(state) {
    return state.library;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
